var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"131384a39a475855332b01219491c05d4e728c0f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV =
    process.env.SENTRY_ENV ||
    process.env.NEXT_PUBLIC_SENTRY_ENV ||
    "development";

Sentry.init({
    dsn:
        SENTRY_DSN ||
        "https://c1873a613f6144adb8d5c5db2eef8167@o1013783.ingest.sentry.io/6044255",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,
    environment: SENTRY_ENV,
    autoSessionTracking: false,
    // REPLAY CONFIGS
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    // integrations: [new Sentry.Replay()],
    ignoreErrors: [
        "SyntaxError: Unexpected token '.'",
        "Unexpected token .",
        "ReferenceError: Can't find variable: fbq",
        "ReferenceError: fbq is not defined",
        "Identifier 'change_ua' has already been declared",
        "Cancel rendering route",
        "Illegal invocation",
        "Failed to execute 'getBattery' on 'Navigator': Illegal invocation",
        "The network connection was lost.",
        "Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document.",
        "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
        "undefined is not an object (evaluating 'window.mraid.getState')",
        "Can't find variable: MoatMAK",
        "Can't find variable: setIOSParameters",
        "Cannot assign to read only property 'setProperty' of object '[object CSSStyleDeclaration]'",
        "Cannot read properties of undefined (reading 'description')",
        "WebKit encountered an internal error",
        `Failed to read the 'contentDocument' property from 'HTMLIFrameElement': Blocked a frame with origin "https://vpnpricecomparison.com" from accessing a cross-origin frame.`,
        "Non-Error promise rejection captured with value: Object Not Found Matching Id:2",
        "Can't find variable: PaymentAutofillConfig",
        "Failed to read the 'cookie' property from 'Document': Access is denied for this document.",
        "Hydration failed because the initial UI does not match what was rendered on the server.",
        "There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.",
        "Text content does not match server-rendered HTML.",
        "TrustedForm Script failed to load: 0",
        "Extension context invalidated.",
        "There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.",
        "Hydration failed because the initial UI does not match what was rendered on the server.",
        "TypeError: Failed to fetch",
        "Failed to execute 'querySelectorAll' on 'Document': 'div:has(> iframe[id='198230182308109283091823098102938908128390'])' is not a valid selector.",
        "TypeError: Load failed"
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});